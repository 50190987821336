/* eslint-disable */
import * as singleSpa from 'single-spa';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './styles.scss';
import { Datacenter, datadogRum } from '@datadog/browser-rum'; // DataDog Analytics - Do not Deploy to prod
import eventBus from './event-bus';
import features from './features';
import { utils } from './utils';

System.import('@iris/environment').then(m => m.default).then(environment => {
  window.environment = environment;
  const isProduction = /true/g.test(environment.production);
  const isTesting = /true/g.test(environment.testing);
  const appHolders = ['angular-app', 'angular-app-with-routing', 'home-app'];

  //disable environment log in Prod/Staging
  if (!isProduction || isTesting) {
    console.log(`Dynamic config: ${JSON.stringify(environment)}`);
  }

  if (isProduction && !isTesting) {
    require('./feature-toggle.scss');
  }

  function showWhenAnyOf(routes) {
    return function (location) {
      return routes.some((route) => location.pathname === route);
    };
  }

  function showWhenPrefix(routes) {
    return function (location) {
      return routes.some((route) => location.pathname.startsWith(route));
    };
  }

  function showExcept(routes) {
    return function (location) {
      return routes.every((route) => !location.pathname.startsWith(route));
    };
  }

  function showWhenPrefixWithParameters(routes) {
    return function (location) {
      // map routes into regex strings, with each parameter getting replaced with a wildcard
      let regexRoutes = [];
      routes.forEach((route) => {
        route = route.replaceAll(/:([^/])+\//g, ')(.+\/)(');
        regexRoutes.push(`(${route}).*`);
      });

      // attempt to match url to each regex
      return regexRoutes.some((route) => location.pathname.match(route));
    };
  }

  function load(module) {
    //hide container while module is loaded.
    //Otherwise we see some artefacts caused by loading a page without it's styles.
    //It will be shown later in the popEvent handler
    appHolders.forEach((app) => {
      document.getElementById(app).style.display = 'none';
    });

    return System.import(module).then(m => m.default);
  }

  singleSpa.registerApplication(
    'login',
    () => load('@iris/authentication-signin-component-pkg'),
    showWhenAnyOf(['/login', '/login/', '/login/forgot-password', '/login/reset-password']),
    { environment },
  );

  singleSpa.registerApplication(
    'layout',
    () => load('@iris/elements-layout-component-pkg'),
    showExcept(['/login', '/signup', '/404', '/customerreferencenumber', '/reset-password']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'export',
    () => load('@iris/elements-admin-roles-component-pkg'),
    showWhenPrefix(['/export']),
    { environment },
  );

  singleSpa.registerApplication(
    'roles',
    () => load('@iris/elements-admin-roles-component-pkg'),
    showWhenPrefix(['/manage']),
    { environment },
  );

  singleSpa.registerApplication(
    'home',
    () => load('@iris/elements-home-component-v01-pkg'),
    showWhenAnyOf(['/', '/home']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'suspicious-activity',
    () => System.import('@iris/elements-suspicious-activity-component-v01-pkg').then(m => m.default),
    showWhenPrefix(['/suspicious-activity']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'kyc',
    () => System.import('@iris/elements-kyc-component-v01-pkg').then(m => m.default),
    showWhenPrefix(['/kyc']),
    { environment, features },
  );

  // if (!isProduction) {
    singleSpa.registerApplication(
      'templates',
      () => System.import('@iris/elements-templates-component-v01-pkg').then(m => m.default),
      showWhenPrefix(['/templates']),
      { environment },
    );
  //}

  singleSpa.registerApplication(
    'risk-assessment',
    () => load('@iris/elements-risk-assessment-component-v01-pkg'),
    showWhenPrefix(['/risk-assessment']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'admin',
    () => load('@iris/elements-admin-component-v00-pkg'),
    showWhenPrefix(['/admin', '/signup', '/customerreferencenumber', '/reset-password', '/interested', '/signincallback']),
    { environment },
  );

  if (!isProduction) {
    singleSpa.registerApplication(
      'personal-tax',
      () => load('@iris/elements-personal-tax-component-v00-pkg'),
      showWhenPrefix(['/personal-tax']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-foreign-income',
      () => load('@iris/tax-individual-foreign-income-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-charitable-giving',
      () => load('@iris/tax-individual-charitable-giving-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-taxadjustments',
      () => load('@iris/tax-individual-taxadjustments-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-gainsoflifeassurance',
      () => load('@iris/tax-individual-gainsoflifeassurance-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-disguisedremuneration',
      () => load('@iris/tax-individual-disguisedremuneration-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-pensioncharges',
      () => load('@iris/tax-individual-pensioncharges-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-shareschemes',
      () => load('@iris/tax-individual-share-schemes-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );
    
    singleSpa.registerApplication(
      'personal-tax-student-loan',
      () => load('@iris/tax-individual-student-loan-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'personal-tax-repayment-claims',
      () => load('@iris/tax-individual-repayment-claims-component-v01-pkg'),
      showWhenPrefix(['/personal-tax/tax-return-overview']),
      { environment, eventBus, features }
    );

    singleSpa.registerApplication(
      'partnership-tax',
      () => load('@iris/tax-partnership-component-v01-pkg'),
      showWhenPrefix(['/partnership-tax']),
      { environment, features }
    );
  }

  singleSpa.registerApplication(
    'company-formation',
    () => load('@iris/elements-company-formation-component-v00-pkg'),
    showWhenPrefix(['/company-formations']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'client-management',
    () => load('@iris/elements-clients-prospects-component-v01-pkg'),
    showWhenPrefix(['/client-management', '/domain-link/risk/assessment', '/domain-link/risk/bulkidcheck']),
    { environment, eventBus, features },
  );


  singleSpa.registerApplication(
    'practicecompliance',
    () => load('@iris/elements-practice-compliance-component-v00-pkg'),
    showWhenPrefix(['/practicecompliance', '/domain-link/risk/firmassessment']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'aml',
    () => load('@iris/elements-aml-component-v01-pkg'),
    showWhenPrefixWithParameters(['/client-management/:clientStatus/:id/aml', '/domain-link/risk/assessment']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'client-dashboard',
    () => load('@iris/elements-client-dashboard-component-v01-pkg'),
    showWhenPrefixWithParameters(['/client-management/:clientStatus/:id/dashboard']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'communications-websockets-app',
    () => load('@iris/communications-websockets-component-v01-pkg'),
    showExcept(['/login']),
    { environment, eventBus },
  );

  singleSpa.registerApplication(
    'communications-notifications-app',
    () => System.import('@iris/communications-notifications-component-v04-pkg').then(m => m),
    isTesting ? showExcept(['/login', '/home']) : showExcept(['/login']),
    { environment, eventBus },
  );

  singleSpa.registerApplication(
    'accounts-production',
    () => load('@iris/elements-accounts-production-component-v00-pkg'),
    showWhenPrefix(['/accounts-production']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'reporting',
    () => load('@iris/elements-reporting-component-v01-pkg'),
    showWhenPrefix(['/reporting']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'accounts-builder',
    () => load('@iris/elements-accounts-builder-component-v01-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/accounts-builder']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'account-period-data',
    () => load('@iris/elements-account-period-data-component-v01-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/source-data', '/accounts-production/periods/:clientId/:periodId/trial-balance']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'accounts-submission',
    () => load('@iris/elements-accounts-submission-component-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/approval', '/accounts-production/periods/:clientId/:periodId/submission']),
    { environment, eventBus, features },
  );  

  singleSpa.registerApplication(
    'xbrl',
    () => load('@iris/xbrl-mapping-components-pkg'),
    showWhenPrefix(['/xbrl']),
    { environment, features },
  );
  
  singleSpa.registerApplication(
    '404',
    () => load('@iris/elements-404-component-v01-pkg'),
    showWhenPrefix(['/404']),
    { environment },
  );

  singleSpa.registerApplication(
    'vault',
    () => load('@iris/elements-vault-component-v00-pkg'),
    showWhenPrefixWithParameters(['/vault']),
    { environment, eventBus, features },
  );

  window.addEventListener('single-spa:routing-event', () => {
    showApps();
    updateHtmlClasses();
  });

  function showApps() {
    appHolders.forEach((app) => {
      const locations = ['/', '/home'];
      if (!locations.includes(window.location.pathname) || !app.startsWith('angular')) {
        document.getElementById(app).style.display = 'block'
      }
      else {
        document.getElementById(app).style.display = 'none';
      }
    });

    // hide the old login screen from some migrated child apps
    if (document.getElementsByTagName('app-login-page')[0]) {
      document.getElementsByTagName('app-login-page')[0].style.display = 'none';
    }
  }

  //Assigns classes based on active apps to avoid styling colision between apps
  function updateHtmlClasses() {
    var element = document.documentElement;

    if (element) {
      const mountedAppNames = singleSpa.getMountedApps();
      element.className = 'hydrated';
      mountedAppNames.forEach(x => { element.className += ' ' + x; });
    }
  }

  window.addEventListener('load', () => {
    // hide the old login screen from some migrated child apps
    //document.getElementById('angular-app').style.display = 'none';
    datadogRum.init({
      applicationId: '4de504e1-952b-42ec-b44b-a99051972f55',
      clientToken: 'pubc720b13f0703532cc4c61f4f2a5d98e6',
      datacenter: Datacenter.EU,
      sampleRate: 100,
      trackInteractions: true
    });
  });

  utils.handle404s(singleSpa);

  singleSpa.start();
});
